export default (props) => ({
  ...props,
  submitted: false,
  async submit($event, $el) {
    this.submitted = true

    const formWrapper = $el.parentNode
    const formWrapperId = formWrapper.dataset.formWrapper

    formWrapper.querySelector('[data-form-error]').classList.add('hidden')

    try {
      const response = await window.fetch($el.getAttribute('action'), {
        method: 'POST',
        body:   new FormData($el),
      })
      this.submitted = false

      if (response.status === 200) {
        const body = await response.text()

        let newChild = document.createElement('div')
        newChild.innerHTML = body
        newChild = newChild.querySelector(`[data-form-wrapper=${formWrapperId}]`)

        formWrapper.parentNode.replaceChild(newChild, formWrapper)

        if (newChild.querySelector('[data-validation-error]')) {
          newChild.querySelector('[data-validation-error]').scrollIntoView({ behavior: 'smooth' })
        }
      } else {
        formWrapper.querySelector('[data-form-error]').classList.remove('hidden')
      }
    } catch (err) {
      console.error(err)
      formWrapper.querySelector('[data-form-error]').classList.remove('hidden')
    }
  },
  onBlur() {
    this.$el.value = this.$el.value.trim()

    if (this.$el.value === '') {
      return
    }

    this.$el.classList.remove('has-errors')
    this.$el.parentNode.querySelector('[data-field-error]')?.remove()
  },
})
