import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import FormComponent from './components/form'

import Swup from 'swup'
import BodyClassPlugin from '@swup/body-class-plugin'
import HeadPlugin from '@swup/head-plugin'
import ScrollPlugin from '@swup/scroll-plugin'
import Glide from '@glidejs/glide'

window.Alpine = Alpine

// Alpine Plugins
Alpine.plugin(collapse)
Alpine.plugin(focus)

// Alpine Components
Alpine.data('Form', FormComponent)

Alpine.start()

const swup = new Swup({
  containers: ['#swup', '#swup-page-header', '#swup-main-menu-mobile', '#swup-page-footer'],
  animationSelector: '[class*="swup-transition-"]',
  plugins: [
    new BodyClassPlugin(),
    new HeadPlugin(),
    new ScrollPlugin({
      doScrollingRightAway: true,
      animateScroll: false,
    }),
  ]
})



function getSliders() {
  if(document.querySelector('.glide')) {
    const sliders = document.querySelectorAll('.glide')
    return sliders
  }
}

function initiateStepSlider() {
  if(document.querySelector('.glide-steps')) {
    const stepSlider = document.querySelector('.glide-steps')

    const glideSteps = new Glide(stepSlider, {
      type: 'slider',
      gap: 40,
      perView: 3,
      bound: true,
      peek: { before: 40, after: 40 },
      breakpoints: {
        1279: {
          perView: 2,
          peek: { before: 40, after: 40 },
        },
        767: {
          perView: 1,
          peek: { before: 20, after: 20 },
        },
      }
    })
    glideSteps.mount()

    // glideSteps.on('run.after', function() {

    //   if (glideSteps.index === 0) {
    //     setTimeout(() => {
    //       glideSteps.update({ animationDuration: 200 })
    //       glideSteps.go('=1')
    //       glideSteps.update({ animationDuration: 400 })
    //     }, 0)
    //   }
    // })
  }
}

function initiateSliders(sliders) {
  if(sliders) {
    for(let i = 0; i < sliders.length; i++) {
      const glide = new Glide(sliders[i], {
        type: 'carousel',
        gap: 20,
        autoplay: 4000,
      })
      glide.mount()
    }
  }
}

function destroySliders(sliders) {
  if(sliders) {
    for(let i = 0; i < sliders.length; i++) {
      console.log(sliders[i])
      // TODO: destroy sliders when navigating away
    }
  }
}

initiateSliders(getSliders())
initiateStepSlider()

swup.hooks.on('link:click', () => {
  destroySliders(getSliders())
})

swup.hooks.on('content:replace', () => {
  initiateSliders(getSliders())
  initiateStepSlider()
})